import { useState } from "react"
import "./NavBar.css";

export default function BetaBanner() {
    const [dimissed, setDismissed] = useState(false)



    return (
        <div className={`alert alert-info ${dimissed ? "d-none" : ""}`}>
            <a href="https://beta.welcomer.app" target="_blank" rel="noreferrer">
            🎉 Welcomer Beta has opened up! Click here to learn more.
            </a>
                <div onClick={() => setDismissed(true)} className="dimiss">
                    X
                </div>

        </div>
    )
}