import { Outlet, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import DashboardPage from "./pages/DashboardPage";
import GuildPage from "./pages/GuildPage";
import InvitePage from "./pages/InvitePage";
import MenuPage from "./pages/MenuPage";
import { useFetchGuilds } from "./utils/hooks/useFetchGuilds";
import { useFetchUser } from "./utils/hooks/useFetchUser";
// import "./index.css";
import Loader from "./components/Loader/Loader";
import BetaBanner from "./components/NavBar/BetaBanner";
import "./components/NavBar/BetaBanner.css";
import CardEdit from "./pages/CardEditor";
import DashEdit from "./pages/DashEdit";
import LegalPage from "./pages/LegalPage";
import StatusPage from "./pages/StatusPage";
import SupportPage from "./pages/SupportPage";
import { UserContext } from "./utils/context/userContext";

function App() {
  const { user, loading } = useFetchUser();
  const { guilds, guildsLoading } = useFetchGuilds();

  function Layout() {
    return (
      <div>
        <NavBar />
        <BetaBanner />
        <Outlet />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ user, loading, guilds, guildsLoading }}>
      <Loader unload={loading && guildsLoading !== false} />
      <div className="App">
        <>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<MenuPage />} />
              <Route path="/invite" element={<InvitePage />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/status" element={<StatusPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/legal" element={<LegalPage />} />
              <Route path="/loader" element={<Loader />} />
              {/* <Route path="/test" element={<CanavaEditor/>}/> */}
              <Route path="*" element={<MenuPage />} />
            </Route>
            <Route path="/edit/:id" element={<CardEdit />} />
            <Route path="/dashboard/:id" element={<GuildPage />} />
            <Route path="/dashboard/:id/:module" element={<DashEdit />} />

            {/* <Route path="/editCard" element={<EditPage/> } /> */}
          </Routes>
        </>
      </div>
    </UserContext.Provider>
  );
}

export default App;
